<template>
  <div class="payment-token flex xs12 mt-5">
    <v-layout row wrap v-show="!displayNotFound">
      <div class="col-12">
        <not-found></not-found>
      </div>
      <div class="col-12">
        <v-alert slot="no-results" :value="true" color="error" icon="warning">
          Password Verfication is required to view / modify access token.
          <a @click="displayPasswordDialog=true" style="color: white; text-decoration:underline;"> Verify Password</a>
        </v-alert>

      </div>
    </v-layout>
    <v-layout row wrap v-show="has_successfully_fetch_token">
      <v-flex>
        <v-card height="100%" class="pa-2 setting-container">
          <v-card-title class="subheading font-weight-bold">
            Add Token
          </v-card-title>
          <v-card-text>
            <v-text-field
              :readonly="display_esewa_key === false"
              :value="
                display_esewa_key
                  ? esewa
                  : '* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * '
              "
              dense
              label="Esewa"
              outlined
              :type="display_esewa_key == true ? 'text' : 'text'"
              :append-icon="esewa != '' ? 
                display_esewa_key == true ? 'visibility' : 'visibility_off':''
              "
              @click:append="hideToken(1)"
              @keyup="updateEsewaKey($event, 1)"
            ></v-text-field>
            <!--===================== 1. ESEWA CLIENT ID AND SECRETKEY FOR MOBILE APP ============================ -->
            <v-text-field
              :readonly="display_esewa_client_id === false"
              :value="
                display_esewa_client_id
                  ? esewa_client_id
                  : '* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * '
              "
              dense
              label="Esewa Client Id"
              outlined
              :type="display_esewa_client_id == true ? 'text' : 'text'"
              :append-icon="(esewa_client_id !='') ?
                display_esewa_client_id == true ? 'visibility' : 'visibility_off':''
              "
              @click:append="hideToken(4)"
              @keyup="updateEsewaKey($event, 4)"
            ></v-text-field>
            <v-text-field
              :readonly="display_esewa_secret_id === false"
              :value="
                display_esewa_secret_id
                  ? esewa_secret_id
                  : '* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * '
              "
              dense
              label="Esewa Sercret Id"
              outlined
              :type="display_esewa_secret_id == true ? 'text' : 'text'"
              :append-icon="(esewa_secret_id != '') ?
                display_esewa_secret_id == true ? 'visibility' : 'visibility_off':''
              "
              @click:append="hideToken(5)"
              @keyup="updateEsewaKey($event, 5)"
            ></v-text-field>
            <v-divider></v-divider>
            <br />
            <!--===================== END 1 -->
            <v-text-field
              :readonly="display_khalti_public_key === false"
              :value="
                display_khalti_public_key
                  ? khalti_public_key
                  : '* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * '
              "
              dense
              label="Khalti Public Key"
              outlined
              :type="display_khalti_public_key == true ? 'text' : 'text'"
              :append-icon="(khalti_public_key != '') ?
                display_khalti_public_key == true
                  ? 'visibility'
                  : 'visibility_off' : ''
              "
              @click:append="hideToken(2)"
              @keyup="updateEsewaKey($event, 2)"
            ></v-text-field>
            
            <v-text-field
              :readonly="display_khalti_authorization_key === false"
              :value="
                display_khalti_authorization_key
                  ? khalti_authorization_key
                  : '* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * '
              "
              dense
              label="Khalti Secret Key"
              outlined
              :type="display_khalti_authorization_key == true ? 'text' : 'text'"
              :append-icon="(khalti_authorization_key != '') ?
                display_khalti_authorization_key == true
                  ? 'visibility'
                  : 'visibility_off' : ''
              "
              @click:append="hideToken(3)"
              @keyup="updateEsewaKey($event, 3)"
            ></v-text-field>

            <v-text-field
              :readonly="display_sms_token === false"
              :value="
                display_sms_token
                  ? sms_token
                  : '* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * '
              "
              dense
              label="Sms Token"
              outlined
              :type="display_sms_token == true ? 'text' : 'text'"
              :append-icon="(sms_token != '') ?
                display_sms_token == true
                  ? 'visibility'
                  : 'visibility_off' : ''
              "
              @click:append="hideToken(6)"
              @keyup="updateEsewaKey($event, 6)"
            ></v-text-field>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <v-btn large block color="info outline" @click="saveToken" v-show="has_successfully_fetch_token">Save</v-btn>
    <v-dialog v-model="displayPasswordDialog" persistent max-width="400px">
      <v-card>
        <v-card-title class="primary white--text">
          <span class="title">Verify Your Password</span>
        </v-card-title>
        <v-card-text class="pb-1 pt-4">
          <div id="login-page">
            <div class="rapper fadeInDown">
              <div id="formContent">
                <form>
                  <div class="login-input">
                      <input
                      :type="unlock === true ? 'text' : 'password'"
                      id="password"
                      class="fadeIn third"
                      autocomplete="off"
                      name="password"
                      v-model="user_password"
                      placeholder="Password"
                    />
                      <v-icon v-if="unlock === false" @click="unlock = true">lock</v-icon>
                      <v-icon v-else @click="unlock = false">lock_open</v-icon>
                  </div>
                </form>
              </div>
  
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="warning"
              text
              @click="displayPasswordDialog=false, displayNotFound=false"
              >Close</v-btn
            >
            <v-btn
              :disabled="user_password === '' ? true:false"
              color="success"
              text
              @click="verifyUser"
            >Verify</v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data: () => {
    return {
      displayOverlay: false,
      has_successfully_fetch_token: false,
      khalti_public_key: "",
      khalti_authorization_key: "",
      esewa: "",
      esewa_client_id:"",
      esewa_secret_id:"",
      sms_token: "",

      display_esewa_key: true,
      display_esewa_client_id: true,
      display_esewa_secret_id: true,
      display_khalti_public_key: true,
      display_khalti_authorization_key: true,
      display_sms_token: true,

      user_password: '',
      displayPasswordDialog: true,
      displayNotFound: true,
      unlock: false,

    };
  },
  
  methods: {
    updateEsewaKey(e, type) {
      if (type == 1) {
        this.esewa = e.target.value;
      } else if (type == 2) {
        this.khalti_public_key = e.target.value;
      } else if(type == 3){
        this.khalti_authorization_key = e.target.value;
      }else if(type == 4){
          this.esewa_client_id = e.target.value;
      }else if(type == 6){
        this.sms_token = e.target.value;
      }else{
          this.esewa_secret_id = e.target.value;
      }
    },
    hideToken(status) {
      if (status == 1) {
        this.display_esewa_key = !this.display_esewa_key;
      } else if (status == 2) {
        this.display_khalti_public_key = !this.display_khalti_public_key;
      } else if(status == 3) {
        this.display_khalti_authorization_key =
          !this.display_khalti_authorization_key;
      }else if(status == 4){
        this.display_esewa_client_id = !this.display_esewa_client_id;
      }else if(status == 6){
        this.display_sms_token = !this.display_sms_token
      }else{
        this.display_esewa_secret_id = !this.display_esewa_secret_id;
      }
    },
    saveToken() {
      if (
        this.esewa != "" ||
        this.esewa_client_id != "" ||
        this.esewa_secret_id != "" ||
        this.khalti_public_key != "" ||
        this.khalti_authorization_key != "" ||
        this.sms_token != ""
      ) {
        this.$rest
          .post("/api/update-merchant", {
            esewa: this.esewa,
            esewa_client_id:this.esewa_client_id,
            esewa_secret_id:this.esewa_secret_id,
            khalti_public_key: this.khalti_public_key,
            khalti_authorization_key: this.khalti_authorization_key,
            sms_token: this.sms_token
          })
          .then((response) => {
            if ((response.status = 200)) {
              this.$events.fire("notification", {
                message: response.data.message,
                status: "success",
              });
            }
          })
          .catch((err) => {
            if (err.response)
              this.$events.fire("notification", {
                message: err.response.data.message,
                status: "error",
              });
          });
      } else {
        this.$events.fire("notification", {
          message: "At least Token for one payment gateway is required",
          status: "error",
        });
      }
    },
    getCompanyProfile() {
      this.$rest
        .get("/api/company-profile")
        .then((response) => {
          this.esewa = response.data.merchant.esewa.merchant_key;
          this.esewa_client_id = response.data.merchant.esewa.client_id;
          this.esewa_secret_id = response.data.merchant.esewa.secret_id;

          this.khalti_public_key = response.data.merchant.khalti.public_key;
          this.khalti_authorization_key =
            response.data.merchant.khalti.authorization_key;
          this.sms_token = response.data.merchant.sms_token;

          if (response.data.merchant.esewa.merchant_key != "") {
            this.display_esewa_key = false;
          }
          if (response.data.merchant.esewa.client_id != "") {
            this.display_esewa_client_id = false;
          }
          if (response.data.merchant.esewa.secret_id != "") {
            this.display_esewa_secret_id = false;
          }

          if (response.data.merchant.khalti.public_key != "") {
            this.display_khalti_public_key = false;
          }
          if (response.data.merchant.khalti.authorization_key != "") {
            this.display_khalti_authorization_key = false;
          }
          if(response.data.sms_token !== ""){
            this.display_sms_token = false;
          }
          this.has_successfully_fetch_token = true
        })
        .catch((err) => {

        });
    },
    verifyUser(){
      this.$rest.post('/api/verfiy-user-for-token', {
        password: this.user_password
      }).then((res)=>{
        this.getCompanyProfile();
        this.displayPasswordDialog = false;
        this.displayOverlay = false
        this.displayNotFound = true;
      }).catch((err) => {
        this.$events.fire("notification", {
          message: err.response.data.message,
          status: "error",
        });
        this.user_password = ''
      })
    }
  },
 
};
</script>
<style>
input[type="password"] {
  -webkit-text-security: square;
}
</style>

<style lang="scss" scoped>
// #login-page {
//   height: 100%;
//   min-height: 100vh;
//   width: 100%;
//   background: #9053c7;
//   background: -webkit-linear-gradient(-135deg, #c850c0, #4158d0);
//   background: -o-linear-gradient(-135deg, #c850c0, #4158d0);
//   background: -moz-linear-gradient(-135deg, #c850c0, #4158d0);
//   background: linear-gradient(-135deg, #c850c0, #4158d0);
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: center;
//   align-items: center;
//   padding: 15px;
// }

/* FORM TYPOGRAPHY*/

input[type="button"],
input[type="submit"],
input[type="reset"] {
  background-color: #56baed;
  border: none;
  color: white;
  // padding: 15px 80px;
  // text-align: center;
  text-decoration: none;
  display: inline-block;
  text-transform: uppercase;
  font-size: 13px;
  -webkit-box-shadow: 0 10px 30px 0 rgba(95, 186, 233, 0.4);
  box-shadow: 0 10px 30px 0 rgba(95, 186, 233, 0.4);
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
  margin: 5px 20px 40px 20px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

input[type="button"]:hover,
input[type="submit"]:hover,
input[type="reset"]:hover {
  background-color: #39ace7;
}

input[type="button"]:active,
input[type="submit"]:active,
input[type="reset"]:active {
  -moz-transform: scale(0.95);
  -webkit-transform: scale(0.95);
  -o-transform: scale(0.95);
  -ms-transform: scale(0.95);
  transform: scale(0.95);
}

input[type="text"],
input[type="password"] {
  background-color: #f6f6f6;
  color: #0d0d0d;
  padding: 15px 32px;
  text-align: left;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 5px;
  width: 100%;
  border: 2px solid #f6f6f6;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
}

input[type="text"]:focus {
  background-color: #fff;
  border-bottom: 2px solid #5fbae9;
}

input[type="text"]:placeholder {
  color: #cccccc;
}

input.red {
  color: red;

  &:focus {
    border-bottom: 2px solid red;
  }
}

/* ANIMATIONS */

/* Simple CSS3 Fade-in-down Animation */
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

/* Simple CSS3 Fade-in Animation */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  opacity: 0;
  -webkit-animation: fadeIn ease-in 1;
  -moz-animation: fadeIn ease-in 1;
  animation: fadeIn ease-in 1;

  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  animation-duration: 1s;
}

.fadeIn.first {
  -webkit-animation-delay: 0.4s;
  -moz-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.fadeIn.second {
  -webkit-animation-delay: 0.6s;
  -moz-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.fadeIn.third {
  -webkit-animation-delay: 0.8s;
  -moz-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.fadeIn.fourth {
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  animation-delay: 1s;
}

/* Simple CSS3 Fade-in Animation */
.underlineHover:after {
  display: block;
  left: 0;
  bottom: -10px;
  width: 0;
  height: 2px;
  background-color: #56baed;
  content: "";
  transition: width 0.2s;
}

.underlineHover:hover {
  color: #0d0d0d;
}

.underlineHover:hover:after {
  width: 100%;
}

/* OTHERS */

*:focus {
  outline: none;
}

#icon {
  width: 200px;
  margin-top: 25px;
}

* {
  box-sizing: border-box;
}

input[type="submit"]:disabled {
  cursor: not-allowed;
  background-color: #ccc;
  box-shadow: none;
}

.error-message {
  color: #ef4545;
  padding: 0 15px;
}

.login-input {
  width: 100%;
  margin: auto;
  position: relative;

  .material-icons {
    position: absolute;
    right: 22px;
    top: 22px;
    cursor: pointer;
  }
}
</style>